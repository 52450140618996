import dayjs from 'dayjs';
import type { AddressbaseFilter, OpeningHoursFilter } from '../gql/schema';
import { PoiFilterProperty } from '../models/BaseFilterInput';

export default (
  stateRef: MaybeRefOrGetter<SearchModel>,
  ignoreFieldsRef?: MaybeRefOrGetter<PoiFilterProperty[]>
): Ref<AddressbaseFilter> => {
  return computed((): AddressbaseFilter => {
    const state = toValue(stateRef);
    const ignoreFields = toValue(ignoreFieldsRef) ?? [];

    const mapDayTimeToRange = (daytime: string) => {
      switch (daytime) {
        case 'morning':
          return {
            from: dayjs().hour(6).minute(0).second(0).format('HH:mm:ss'),
            to: dayjs().hour(12).minute(0).second(0).format('HH:mm:ss'),
          };
        case 'afternoon':
          return {
            from: dayjs().hour(12).minute(0).second(0).format('HH:mm:ss'),
            to: dayjs().hour(18).minute(0).second(0).format('HH:mm:ss'),
          };
        case 'evening':
          return {
            from: dayjs().hour(18).minute(0).second(0).format('HH:mm:ss'),
            to: dayjs().hour(23).minute(59).second(59).format('HH:mm:ss'),
          };
        default:
          throw new Error(`Unknown daytime value: ${daytime}`);
      }
    };

    const dayTimeRanges = state.daytime?.map(mapDayTimeToRange);

    const openingHoursFilter: OpeningHoursFilter = {
      openAt: undefined,
      openOn: undefined,
      openToday: undefined,
    };
    if (
      !isEmpty([state.dateFrom, state.dateTo], 'some') &&
      state.dateFrom === state.dateTo
    ) {
      const today = dayjs().format('YYYY-MM-DD');
      if (state.dateFrom === today) {
        openingHoursFilter.openToday = true;
      } else {
        openingHoursFilter.openAt = state.dateFrom
          ? dayjs(state.dateFrom).toDate()
          : undefined;
      }
    } else if (!isEmpty([state.dateFrom, state.dateTo], 'some')) {
      openingHoursFilter.openOn = {
        oneOf: [
          getWeekdayFromDate(state.dateFrom!),
          getWeekdayFromDate(state.dateTo!),
        ],
      };
    }

    const openingHoursDayTimeFilter = {
      or: dayTimeRanges.map((daytime) => ({
        openingHours: {
          openAtTimeOfDay: { from: daytime.from, to: daytime.to },
        },
      })),
    };

    const userFilter: AddressbaseFilter = {
      fulltext: state.search.join(' '),
      productlines: {
        oneOf: state.categories,
        allOf: state.criteria,
      },
      and: [{ openingHours: openingHoursFilter }, openingHoursDayTimeFilter],
      location: state.locationId ? { eq: state.locationId } : undefined,
      group:
        state.locationType === 'AddressPoiGroup'
          ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
          : undefined,
      regions:
        state.locationType === 'Region'
          ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
          : undefined,
      geoFilter: {
        distanceFromPoint:
          state.latitude && state.longitude
            ? {
                point: {
                  latitude: state.latitude,
                  longitude: state.longitude,
                },
                maxDistance: state.radius || 5000,
              }
            : null,
      },
    };

    if (
      (ignoreFields as PoiFilterProperty[]).indexOf(
        PoiFilterProperty.CATEGORY
      ) !== -1
    ) {
      delete userFilter.productlines?.oneOf;
    }
    if (
      (ignoreFields as PoiFilterProperty[]).indexOf(
        PoiFilterProperty.ATTRIBUTE
      ) !== -1
    ) {
      delete userFilter.productlines?.allOf;
    }

    return userFilter;
  });
};
