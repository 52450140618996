import dayjs from 'dayjs';
import type { EventFilter } from '../gql/schema';
import { EventFilterProperty } from '../models/BaseFilterInput';

export default (
  stateRef: MaybeRefOrGetter<SearchModel>,
  ignoreFieldsRef?: MaybeRefOrGetter<EventFilterProperty[]>
): Ref<EventFilter> => {
  return computed((): EventFilter => {
    const state = toValue(stateRef);
    const ignoreFields = toValue(ignoreFieldsRef) ?? [];

    const mapDayTimeToRange = (daytime: string) => {
      switch (daytime) {
        case 'morning':
          return {
            from: dayjs().hour(6).minute(0).second(0).format('HH:mm:ss'),
            to: dayjs().hour(12).minute(0).second(0).format('HH:mm:ss'),
          };
        case 'afternoon':
          return {
            from: dayjs().hour(12).minute(0).second(0).format('HH:mm:ss'),
            to: dayjs().hour(18).minute(0).second(0).format('HH:mm:ss'),
          };
        case 'evening':
          return {
            from: dayjs().hour(18).minute(0).second(0).format('HH:mm:ss'),
            to: dayjs().hour(23).minute(59).second(59).format('HH:mm:ss'),
          };
        default:
          throw new Error(`Unknown daytime value: ${daytime}`);
      }
    };

    const dayTimeRanges = state.daytime?.map(mapDayTimeToRange);

    const userFilter: EventFilter = {
      fulltext: state.search?.join(' '),
      fromDate: state.dateFrom
        ? dayjs(state.dateFrom).format('YYYY-MM-DD')
        : undefined,
      toDate: state.dateTo
        ? dayjs(state.dateTo).format('YYYY-MM-DD')
        : undefined,
      or: dayTimeRanges.map((daytime) => ({
        startTime: { from: daytime.from, to: daytime.to },
      })),
      pricingFilter: state.onlyFree ? { freeOfCharge: true } : undefined,
      categories: !isEmpty(state.categories)
        ? { oneOf: state.categories }
        : undefined,
      criteria: !isEmpty(state.criteria)
        ? { allOf: state.criteria }
        : undefined,
      eventLocation: {
        regions:
          state.locationType === 'Region'
            ? {
                oneOf: [state.locationId].filter((id) => isDefined(id)),
              }
            : undefined,
        location:
          state.locationType === 'Location'
            ? { eq: state.locationId }
            : undefined,
        group:
          state.locationType === 'AddressPoiGroup'
            ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
            : undefined,
      },
      geoFilter: {
        distanceFromPoint:
          state.latitude && state.longitude
            ? {
                point: {
                  latitude: state.latitude,
                  longitude: state.longitude,
                },
                maxDistance: state.radius ? state.radius : 5000,
              }
            : null,
      },
    };

    if (
      (ignoreFields as EventFilterProperty[]).indexOf(
        EventFilterProperty.CATEGORY
      ) !== -1
    ) {
      delete userFilter.categories;
    }
    if (
      (ignoreFields as EventFilterProperty[]).indexOf(
        EventFilterProperty.CRITERION
      ) !== -1
    ) {
      delete userFilter.criteria;
    }

    return userFilter;
  });
};
