import type { AppearanceFilter } from '../gql/schema';

export interface BaseFilterInput<T> {
  filter: T;
  appearance: AppearanceFilter | null;
}

export enum EventFilterProperty {
  CATEGORY,
  CRITERION,
}

export enum PoiFilterProperty {
  ATTRIBUTE,
  CATEGORY,
}

export enum TourFilterProperty {
  CATEGORY,
  ATTRIBUTE,
}
